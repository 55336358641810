import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/layout/layout";
import "../contact.scss";
import axios from "axios";
import { urls } from "../../../config";
import { useTranslation } from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const ContactBD = ({ data: { ribbon, header, footer } }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [finished, setFinished] = React.useState(false);
  const { t, language } = useTranslation();

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (!name || !email || !message || !category) {
      setError(t("fillAllFormFields"));
      return;
    }
    const result = await axios({
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: `${urls.contact}`,
      data: {
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        category: category,
        message: message,
        contactType: "BUSINESS_DEVELOPMENT",
      },
    });

    if (result.data && result.data.error) {
      setError(result.data.error);
    } else if (result.data && result.data.result) {
      setFinished(true);
    } else {
      setError(t("oopsError"));
    }
  };
  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="white-banner"
    >
      <section className="contact-page">
        <div className="form-wrapper">
          <h1>{t("partnerships")}</h1>
          {finished ? (
            <p className={"message success"}>{t("contactSendSuccess")}</p>
          ) : (
            <form>
              <input
                type="text"
                value={name}
                placeholder={`${t("yourName")}*`}
                onChange={(event) => setName(event.target.value)}
              />
              <input
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder={`${t("email")}*`}
              />
              <input
                type="text"
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                placeholder={`${t("phoneNumber")}*`}
              />
              <select
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                <option value="">{t("category")} *</option>
                <option value="gyms">Gyms</option>
                <option value="universities-schools">
                  Universities and Schools
                </option>
                <option value="hotel-travel">Hotel/Travel</option>
                <option value="fitness-brands">Fitness Brands</option>
                <option value="corporate">Corporate</option>
                <option value="trainer">Trainer</option>
                <option value="other">Other</option>
              </select>
              <textarea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder={`${t("writeMessage")}*`}
              ></textarea>
              <label className="required-text">
                <sub>*</sub> {t("requiredFields")}
              </label>
              {error && <p className={"message error"}>{error}</p>}
              <button type="submit" onClick={onFormSubmit}>
                Submit
              </button>
            </form>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default ContactBD;
